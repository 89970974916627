class ContentSwitch {
    constructor() {
        const initContentSwitch = this.initContentSwitch.bind(this);
        initContentSwitch();
    }

    initContentSwitch() {
        const buttons = document.querySelectorAll(
            "button.tariff-switch-button"
        );
        const buttonSelectedClass = "tariff-switch-button--selected";
        const tariffs = document.querySelectorAll(".tariff-wrapper");
        const tariffSelectedClass = "tariff-wrapper--selected";

        buttons.forEach((button, currentIndex) => {
            button.onclick = function () {
                button.classList.add(buttonSelectedClass);
                buttons.forEach((button, index) => {
                    if (currentIndex != index) {
                        button.classList.remove(buttonSelectedClass);
                    }
                });
                tariffs.forEach((tariff, index) => {
                    if (currentIndex == index) {
                        tariff.classList.add(tariffSelectedClass);
                    } else {
                        tariff.classList.remove(tariffSelectedClass);
                    }
                });
            };
        });
    }
}

window.ContentSwitch = new ContentSwitch();
