class EmbeddedTrustedShops {
  constructor() {
    this.badge = null;
    this.config = {
      tsID: '',
      tsConfig: {
        'variant': 'custom', /* default, reviews, custom, custom_reviews */
        'customElementId': 'trusted-shops-snippet', /* required for variants custom and custom_reviews */
        'disableResponsive': 'false', /* deactivate responsive behaviour */
        'disableTrustbadge': 'false', /* deactivate trustbadge */
        'trustcardDirection': 'bottomLeft', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
        'responsive': { 'variant': 'floating', 'position': 'left' },
      },
    };
    this.customElement = document.getElementById(this.config.tsConfig.customElementId);
    if (this.customElement && this.loadConfig()) {
      this.initBadge();
    }
  }

  loadConfig = () => {
    const size = {
      w: this.customElement.dataset.width || 100,
      h: this.customElement.dataset.height || 120
    };
    this.config.tsID = this.customElement.dataset.tsId || '';
    this.config.tsConfig.disableResponsive = typeof this.customElement.dataset.responsive == 'boolean' && this.customElement.dataset.responsive ? 'false' : 'true';
    this.config.tsConfig.hideOn = typeof this.customElement.dataset.hideOn !== 'undefined' ? this.customElement.dataset.hideOn.split(',') : [];
    this.config.tsConfig.customBadgeHeight = size.h.toString();
    this.config.tsConfig.customBadgeWidth = size.w.toString();

    return !!this.config.tsID;
  }

  initBadge = () => {
    window._tsConfig = this.config.tsConfig;
    const _ts = document.createElement('script');
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.async = true;
    _ts.src = '//widgets.trustedshops.com/js/' + this.config.tsID + '.js';
    const __ts = document.getElementsByTagName('script')[0];
    __ts.parentNode.insertBefore(_ts, __ts);

    // ECFE-3945: hide badge auf dem legal/footer-Element
    let badge = null;
    const legalContent = document.getElementsByClassName('legal-content'),
      options = {
        rootMargin: '0px',
      },
      badgeHideObserver = new IntersectionObserver(function (entries, observer) {
        if (!badge) {
          badge = document.getElementById('trusted-shops-snippet');
        }
        if (badge) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              if (badge.style.display !== 'none') {
                badge.style.display = 'none';
              }
            } else {
              if (badge.style.display === 'none') {
                badge.style.display = 'block';
              }
            }
          });
        }
      }, options);

    badgeHideObserver.observe(legalContent[0]);
  }
}

window.embeddedTrustedShops = new EmbeddedTrustedShops();
