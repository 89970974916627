class Tracking {
  constructor() {
    /*  https://developer.mozilla.org/en-US/docs/Web/API/Window/parent
     *  If a window does not have a parent, its parent property is a reference to itself.
     */
    try {
      window.parent.dataLayer = window.parent.dataLayer || [];
    } catch (e) {
      window.dataLayer = window.dataLayer || [];
    }

    const initMenuVisibilityTracking = this.initMenuVisibilityTracking.bind(this),
      initMenuClick = this.initMenuClick.bind(this),
      initStageTracking = this.initStageTracking.bind(this),
      initTileTracking = this.initTileTracking.bind(this),
      initStickyPhone = this.initStickyPhone.bind(this);
      
    window.setTimeout(() => {
      if ((window.innerWidth || document.documentElement.clientWidth) >= 768) {
        //mobile
        initMenuVisibilityTracking();
      }

      initStageTracking();
      initTileTracking();
      initMenuClick();
      initStickyPhone();
    }, 500);

    document.addEventListener('scroll', (event) => {
      initStageTracking();
      initTileTracking();
      initStickyPhone();
    });
  }

  initMenuVisibilityTracking() {
    this.initVisibility('header nav>ol', .5, {
      'event': 'component_visible',
      'Komponente': 'Header Sprungmarke',
    })
  }

  initMenuClick() {
    const links = document.querySelectorAll('header nav>ol>li>a');
    var that = this;

    links.forEach((a) => {
      a.onclick = (function (aktion, that) {
        return () => {
          that.track({
            'event': 'component_interaction',
            'Komponente': 'Header Sprungmarke',
            'Aktion': aktion,
            'Kontext': 'navigation'
          });
        }
      })(a.innerText, that);
    });
  }

  initStageTracking() {
    try {
      const anchor = document.querySelector('header .add-to-cart a');
      const link = anchor.href;

      anchor.onclick = ((e) => {
        this.track({
          'event': 'component_interaction',
          'Komponente': 'Bühne',
          'Aktion': 'Jetzt bestellen',
          'Kontext': link
        });
      }).bind(this);

      this.initVisibility('header .tariff', .5, {
        'event': 'component_visible',
        'Komponente': 'Bühne',
        'Kontext': link
      });
    } catch (e) { }
  }

  initTileTracking() {
    const that = this;
    const anchors = document.querySelectorAll('.tariff-container .add-to-cart a');
    anchors.forEach((anchor, index) => {
      const link = anchor.getAttribute('href');
      this.initVisibility(`.tariff-container:nth-of-type(${index+1}) .add-to-cart a`, .5, {
          'event': 'component_visible',
          'Komponente': 'Angebotskachel',
          'Kontext': link
      });

      anchor.onclick = (function (link, that) {
        return function () {
          that.track({
            'event': 'component_interaction',
            'Komponente': 'Angebotskachel',
            'Aktion': 'Jetzt bestellen',
            'Kontext': link
          });
        }
      })(link, that);
    });
  }

  initStickyPhone() {
    const selector = '.contact-bubble-wrapper i';
    const phone = document.querySelector(selector);
    if (phone) {
      this.initVisibility(selector, .5, {
          'event': 'component_visible',
          'Komponente': 'Sticky Telefon'
      });

      phone.onclick = () => {
        this.track({
          'event': 'component_interaction',
          'Komponente': 'Sticky Telefon',
          'Aktion': 'Button Klick',
        });
      }
    }
  }


  initVisibility(selector, percent, event) {
    if (!this.alreadyTracked(event)) {
      const domNode = document.querySelector(selector);
      if (!domNode) {
        return false;
      }
      if (this.isInViewport(domNode, percent)) {
        this.track(event);
        return true;
      }
    }
    return false;
  }

  track(event) {
    window.dataLayer.push(event);
  }

  alreadyTracked(event) {
    if (window.dataLayer && window.dataLayer.find(e => e.event === event.event && e.Komponente === event.Komponente && e.Kontext === event.Kontext)) {
      return true;
    }
    return false;
  }

  isInViewport(elem, percent) {
    var bounding = elem.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    const halfHeight = bounding.height * percent,
      halfWidth = bounding.width * percent;
    return (
      bounding.top + halfHeight >= 0 &&
      bounding.left + halfWidth >= 0 &&
      bounding.top + halfHeight <= windowHeight &&
      bounding.left + halfWidth <= windowWidth
    );
  }
}

window.Tracking = new Tracking();
